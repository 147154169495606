import WidgetHeader from 'components/TasksV2/UI/WidgetHeader'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import React, { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@chakra-ui/core'
import { ImageQeustion } from './ImageQeustion'
import { useWidgetErrorMessage } from '../../useWidgetErrorMessage'
import { WidgetProps } from '../types'

const ImageWidget: React.FC<WidgetProps> = memo(({ index, isDragging, field, remove, subtaskPath }) => {
  const { control, errors } = useFormContext()

  const questionName = `${subtaskPath}.title`
  const imageError = useWidgetErrorMessage({ errors, index, field })

  return (
    <WidgetWrapper pos="relative" p="10px" isError={!!imageError} errorMessage={imageError} isDragging={isDragging}>
      <WidgetHeader onRemove={remove} subtaskType={field.type} />
      <ImageQeustion questionName={questionName} field={field} />

      {/*Register constant values in the form context*/}
      <Controller
        name={`${subtaskPath}.type`}
        control={control}
        as={<Box />}
        key={`${subtaskPath}.type`}
        defaultValue={field.type}
      />
    </WidgetWrapper>
  )
})

export default ImageWidget
