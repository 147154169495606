import { RootState, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { calculateResultContextID } from 'redux/actions/tasks_v2'
import { userIdSelector } from 'redux/selectors/config'
import { extractSubstringAfterDelimiter } from 'utils'

export enum TaskOverviewContentType {
  MAIN = 'MAIN',
  VOTES_OVERVIEW = 'VOTES_OVERVIEW',
  MEDIA_SUBTASK_COMPLETING = 'MEDIA_SUBTASK_COMPLETING',
  OPEN_QUESTION_OVERVIEW = 'OPEN_QUESTION_OVERVIEW',
}

export interface ITaskOverviewContentStack {
  contentType: TaskOverviewContentType
  props?: any
}

export const parseRecurrenceTimestamp = (recurrenceTs: string) => {
  return dayjs(recurrenceTs, 'YYYYMMDDHHmmss').unix()
}

interface TaskOverviewContextProps {
  contentStack: ITaskOverviewContentStack[]
  setContentStack: React.Dispatch<React.SetStateAction<ITaskOverviewContentStack[]>>
  openContent: (contentType: TaskOverviewContentType, props?: any) => void
  closeContent: () => void
  task: Task
  resultContextId: string
  isTaskCompleted: boolean
  isSubtaskCompleted: boolean
  isReadMode: boolean
  isShowResetMenu: boolean
  isComeFromDashboard: boolean
}

const TaskOverviewStackContext = createContext<TaskOverviewContextProps | undefined>(undefined)

export const TaskOverviewStackProvider: React.FC<{
  children: React.ReactNode
  task: Task
  onClose: VoidFunction
  isComeFromDashboard?: boolean
  content?: ITaskOverviewContentStack
}> = ({ children, task, content, onClose, isComeFromDashboard = false }) => {
  const { tid, recipient_type, is_individual_results, retail_id_context_id, dashboardContextId, created_by } = task
  const [contentStack, setContentStack] = useState<ITaskOverviewContentStack[]>([
    content ? { ...content } : { contentType: TaskOverviewContentType.MAIN },
  ])

  const { allResults, subtasks } = useSelector((state: RootState) => state.tasks_v2)
  const uid = useSelector(userIdSelector)!
  const {
    activeGroupID,
    config: { rid, tags, permission },
  } = useSelector((state: RootState) => state.config)

  const resultContextId = useMemo(() => {
    return calculateResultContextID({
      recipient_type: recipient_type,
      is_individual_results: is_individual_results,
      uid: uid,
      groupId: activeGroupID!,
      tagId: recipient_type === 2 ? extractSubstringAfterDelimiter(retail_id_context_id, rid) : null,
      retail_id_context_id: retail_id_context_id,
      rid,
      tags,
      dashboardContextId,
    })
  }, [activeGroupID, dashboardContextId, is_individual_results, recipient_type, retail_id_context_id, rid, tags, uid])

  const subtasksOfTask = subtasks[tid]

  const taskResults =
    allResults?.[tid]?.filter(
      (result) => result.context_id === dashboardContextId || result.context_id === resultContextId
    ) ?? []

  const isTaskCompleted = useMemo(() => {
    const subtasksLength = subtasksOfTask?.length
    const isAllResultsDone = taskResults?.every(
      (result) => result.status === keys.TASK_STATUSES.DONE.value || result.status === -1
    )

    return isAllResultsDone && taskResults.length === subtasksLength
  }, [subtasksOfTask, taskResults])

  const isSubtaskCompleted = useMemo(() => {
    const selectedSubtask = contentStack[contentStack.length - 1]?.props?.selectedSubtask
    if (selectedSubtask) {
      const result = taskResults?.find((result) => result.st_id === selectedSubtask.st_id)
      return result?.status === keys.TASK_STATUSES.DONE.value
    }
    return false
  }, [contentStack, taskResults])

  const isReadMode = !(resultContextId === uid || resultContextId === activeGroupID)

  const isShowResetMenu =
    isComeFromDashboard && (created_by === uid || permission?.tasks === keys.USER_ACTIONS_MAP.APP_ADMIN)

  const openContent = useCallback((contentType: TaskOverviewContentType, props?: any) => {
    setContentStack((prevStack) => [...prevStack, { contentType, props }])
  }, [])

  const closeContent = useCallback(() => {
    setContentStack((prevStack) => {
      const newStack = prevStack.slice(0, -1)
      return newStack
    })
  }, [])
  useEffect(() => {
    if (contentStack.length === 0) {
      onClose()
    }
  }, [contentStack, onClose])

  return (
    <TaskOverviewStackContext.Provider
      value={{
        contentStack,
        setContentStack,
        openContent,
        closeContent,
        task,
        isTaskCompleted,
        isSubtaskCompleted,
        resultContextId,
        isReadMode,
        isShowResetMenu,
        isComeFromDashboard,
      }}
    >
      {children}
    </TaskOverviewStackContext.Provider>
  )
}

export const useTaskOverviewContext = () => {
  const context = useContext(TaskOverviewStackContext)
  if (!context) {
    throw new Error('useTaskOverviewContext must be used within a ContentStackProvider')
  }
  return context
}
