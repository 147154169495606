import { Avatar, Flex, Text } from '@chakra-ui/core'
import dayjs from 'dayjs'
import React from 'react'

import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

dayjs.extend(localeData)

interface IProps {
  avatar?: string
  name: string
  date?: string
}
const ContextInfo: React.FC<IProps> = ({ avatar, name: name, date }) => {
  const { locale } = useSelector((state: RootState) => state.general)
  return (
    <Flex direction="row" alignItems="center" fontSize="16px" style={{ columnGap: '10px' }}>
      <Avatar src={avatar} />
      <Flex direction="column" alignItems="flex-start" justifyContent="center">
        <Text fontWeight={600}>{name}</Text>
        {date && <Text>{dayjs(date).locale(locale).format('DD MMMM YYYY HH:mm')}</Text>}
      </Flex>
    </Flex>
  )
}
export default ContextInfo
