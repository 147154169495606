import React, { memo, useCallback, useMemo, useState } from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import { useDispatch, useSelector } from 'react-redux'
import { Result, RootState, Subtask } from 'constants/interfaces'
import { useContextCommentsCounter } from 'components/TasksV2/hooks/useContexsCommentsCounter'
import { ConmmentsCount } from 'components/TasksV2/CommentsSection/ConmmentsCount'
import {
  TaskOverviewContentType,
  useTaskOverviewContext,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import colors from 'constants/colors'
import { ResetMenu } from 'components/TasksV2/UI/ResetMenu'
import useLocaleText from 'components/useLocaleText'
import { Box } from '@chakra-ui/core'
import { isRtlSelector } from 'redux/selectors/general'
import { resetSubtasksResults } from 'components/TasksV2/TasksOverview/utils'
import { AppDispatch } from 'config/redux'
import { resetResults } from 'redux/actions/tasks_v2'

interface IProps {
  isSubtaskDone: boolean
  subtask: Subtask
  renderContent: JSX.Element
  subTaskResult?: Result
}

const BaseSubtask: React.FC<IProps> = memo(({ isSubtaskDone, subtask, renderContent, subTaskResult }) => {
  const dispatch: AppDispatch = useDispatch()
  const { resultContextId, task, openContent, isReadMode, isShowResetMenu } = useTaskOverviewContext()
  const { title } = subtask
  const { recurrence_ts, tid } = task

  const [isLoading, setIsLoading] = useState(false)

  const handleResetResults = async () => {
    if (!subTaskResult) {
      return
    }
    setIsLoading(true)
    const isResOk = await dispatch(
      resetResults({
        tid,
        sub_tasks_ids: [subTaskResult.st_id],
        result_context_id: resultContextId,
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
    )
    if (!isResOk) {
      return
    }

    resetSubtasksResults({
      tid,
      filteredResultsBySubtasksIds: [subTaskResult],
      contextId: resultContextId,
      dispatch,
      ...(recurrence_ts ? { recurrence_ts } : {}),
    })
    setIsLoading(false)
  }

  const isRtl = useSelector(isRtlSelector)
  const reset_answer = useLocaleText('reset_answer')

  const contextIdRecurrenceTs = useMemo(
    () => `${resultContextId}${recurrence_ts ? '_' + recurrence_ts : ''}`,
    [recurrence_ts, resultContextId]
  )

  const oid = useMemo(() => `${subtask.st_id}_${contextIdRecurrenceTs}`, [contextIdRecurrenceTs, subtask.st_id])

  const { totalCommentsCount, loading } = useContextCommentsCounter([oid])
  const viewedCommentsIds = useSelector((state: RootState) => state.comments.viewedCommentsIds[oid])
  const unredCommentsCounter =
    totalCommentsCount - (viewedCommentsIds?.length || 0) >= 0
      ? totalCommentsCount - (viewedCommentsIds?.length || 0)
      : 0

  const isForbiddenToOpen = isReadMode && !subTaskResult
  const onSubTaskClick = useCallback(() => {
    if (!subtask || isForbiddenToOpen) {
      return
    }
    openContent(TaskOverviewContentType.MEDIA_SUBTASK_COMPLETING, { selectedSubtask: subtask })
  }, [isForbiddenToOpen, openContent, subtask])

  return (
    <WidgetWrapper
      cursor={isForbiddenToOpen ? 'default' : 'pointer'}
      p="15px"
      onClick={onSubTaskClick}
      pos="relative"
      pointerEvents={isLoading ? 'none' : 'all'}
      opacity={isLoading ? 0.5 : 1}
    >
      {renderContent}
      {isShowResetMenu && (
        <Box
          pos="absolute"
          top="3px"
          right={isRtl ? 'unset' : '25px'}
          left={isRtl ? '25px' : 'unset'}
          onClick={(e) => e.stopPropagation()}
        >
          <ResetMenu menuItemText={reset_answer} handleReset={handleResetResults} isLoading={isLoading} />
        </Box>
      )}
      {isSubtaskDone && <StatusCircle color={colors.greenPoll} />}
      {subTaskResult && (
        <ConmmentsCount
          totalCommentsCount={totalCommentsCount}
          unredCommentsCounter={unredCommentsCounter}
          loading={loading}
        />
      )}

      <SubtaskTitile title={title} mt="35px" mb="25px" />
    </WidgetWrapper>
  )
})
export default BaseSubtask
