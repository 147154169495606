import React, { memo, useMemo, useState } from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { Avatar, Flex, Spinner, Text, useToast } from '@chakra-ui/core'
import keys from 'constants/keys'
import { ChartNoAxesColumn, ChevronLeft, ChevronRight, Image, Link, Video } from 'lucide-react'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import colors from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { AppDispatch } from 'config/redux'
import { getTask } from 'redux/actions/tasks_v2'
import { RootState } from 'constants/interfaces'
import { useContextCommentsCounter } from 'components/TasksV2/hooks/useContexsCommentsCounter'
import { ConmmentsCount } from 'components/TasksV2/CommentsSection/ConmmentsCount'
import { getAvatarSrc } from 'components/Tasks/utils'
import { BsQuestionCircle } from 'react-icons/bs'

const getStatusConfig = (statusValue: number) => {
  if (statusValue === keys.TASK_STATUSES.DONE.value) {
    return { color: colors.analyticGreen, showIcon: true }
  }
  if (statusValue === keys.TASK_STATUSES.INPROGRESS.value) {
    return { color: colors.analyticYellow, showIcon: false }
  }
  return { color: colors.analyticsGreyLight, showIcon: false }
}

const typeIcons: { [key: string]: JSX.Element } = {
  2: <Image size={20} />,
  3: <Link size={20} />,
  4: <Video size={20} />,
  6: <ChartNoAxesColumn size={20} />,
  8: <BsQuestionCircle size={18} />,
}

interface IProps {
  contextId: string
  statusValue: number
  typeCounts: { [type: number]: number } | null
  taskContextId: string
  tid: string
  onOpen: VoidFunction
  recurrenceTs: string | null
  name: string
}
export const ContextItem: React.FC<IProps> = memo(
  ({ contextId, tid, statusValue, typeCounts, taskContextId, recurrenceTs, onOpen, name }) => {
    const dispatch: AppDispatch = useDispatch()
    const toast = useToast()
    const { groups, retailUsersObject } = useSelector((state: RootState) => state.config.config)
    const { color } = getStatusConfig(statusValue)
    const avatar = getAvatarSrc({ id: contextId, groups: groups, retailUsersObject: retailUsersObject! })

    const isRtl = useSelector(isRtlSelector)
    const viewedCommentsIds = useSelector((state: RootState) => state.comments.viewedCommentsIds)
    const {
      deepDiveInsights: { insights },
    } = useSelector((state: RootState) => state.tasks_v2)

    const [isFetching, setFetching] = useState(false)

    const contextIdRecurrenceTs = `${contextId}${recurrenceTs ? '_' + recurrenceTs : ''}`

    const oids = useMemo(() => {
      const context = insights[contextId]
      if (!context || !context.items) {
        return []
      }
      return context.items
        .filter(
          (item) =>
            item.status === keys.TASK_STATUSES.DONE.value &&
            (item.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK || item.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS)
        )
        .map((item) => `${item.st_id}_${contextIdRecurrenceTs}`)
    }, [contextId, contextIdRecurrenceTs, insights])

    const { totalCommentsCount, loading } = useContextCommentsCounter(oids)

    const viewedIds = useMemo(
      () =>
        oids.reduce<string[]>((acc, oid) => {
          if (viewedCommentsIds[oid]) {
            acc.push(...viewedCommentsIds[oid])
          }
          return acc
        }, []),
      [oids, viewedCommentsIds]
    )
    const unredCommentsCounter =
      totalCommentsCount - (viewedIds?.length || 0) >= 0 ? totalCommentsCount - (viewedIds?.length || 0) : 0

    const handleContextClick = async () => {
      try {
        setFetching(true)
        const query = {
          tid,
          task_context_id: taskContextId,
          result_context_id: contextId,
          recurrence_ts: recurrenceTs,
        }
        const isResultOk = await dispatch(getTask({ ...query }))
        if (isResultOk) {
          onOpen()
        } else {
          toast({
            status: 'error',
            title: 'Error',
            description: 'Something went wrong',
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        setFetching(false)
      }
    }

    return (
      <WidgetWrapper
        p="20px"
        h="92px"
        pos="relative"
        cursor="pointer"
        borderRadius="12px"
        border="1px solid rgba(179, 184, 196, 0.4)"
        boxShadow="rgba(173, 173, 173, 0.25) 0px 2px 8px 0px"
        onClick={handleContextClick}
      >
        <Flex flexDir="column" w="100%" style={{ rowGap: '10px' }}>
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Flex alignItems="center" style={{ columnGap: '25px' }}>
              <StatusCircle color={color} pos="relative" top="unset" right="unset" left="unset" />
              <Avatar size="md" name={name} src={avatar} />
              <Text
                fontSize="16px"
                maxW={{ base: '150px', md: '190px', xl: '100%' }}
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {name}
              </Text>
            </Flex>
            <Flex>
              <Flex alignItems="center" style={{ columnGap: '20px' }} px="20px">
                {typeCounts &&
                  Object.entries(typeCounts).map(([type, count]) => (
                    <Flex key={type} alignItems="center" color="black">
                      {typeIcons[type]}
                    </Flex>
                  ))}

                <ConmmentsCount
                  totalCommentsCount={totalCommentsCount}
                  unredCommentsCounter={unredCommentsCounter}
                  loading={loading}
                  pos="relative"
                  left="unset"
                  right="unset"
                  bottom="unset"
                />
              </Flex>
              {isFetching ? (
                <Spinner h="20px" w="20px" borderWidth="1px" color={colors.analyticGrey} />
              ) : (
                <>{isRtl ? <ChevronLeft /> : <ChevronRight />}</>
              )}
            </Flex>
          </Flex>
        </Flex>
      </WidgetWrapper>
    )
  }
)
