import { Box, CheckboxGroup, Flex, Skeleton } from '@chakra-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { RadioCheckbox } from './RadioCheckbox'
import { Result, RootState } from 'constants/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { updateResults } from 'redux/actions/tasks_v2'
import { AppDispatch } from 'config/redux'
import keys from 'constants/keys'
import { PollBar } from 'components/TasksV2/UI/PollBar'
import { PollVotedAvatars } from 'components/TasksV2/UI/PollVotedAvatars'
import { buildPollOptions } from 'components/TasksV2/TasksOverview/utils'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'

interface IProps {
  options: string[]
  isMultiple: boolean
  pollResults: Result[]
  st_id: string
  tid: string
}

export const Votes: React.FC<IProps> = memo(({ options, isMultiple, pollResults, st_id, tid }) => {
  const dispatch: AppDispatch = useDispatch()
  const { resultContextId, isReadMode } = useTaskOverviewContext()

  const { isLoading, deepDiveInsights } = useSelector((state: RootState) => state.tasks_v2)
  const { users: permittedUsers, groups: permittedGroups } = useSelector(
    (state: RootState) => state.config.userWriteTaskPermissions
  )
  const { groups, retailUsersObject } = useSelector((state: RootState) => state.config.config)

  const [selectedValues, setSelectedValues] = useState<number[]>(
    pollResults
      ?.filter((result) => result.context_id === resultContextId)
      .flatMap((result) => result.poll_selections ?? []) ?? []
  )

  const optionsArray = buildPollOptions({
    options,
    pollResults,
    permittedUsers,
    permittedGroups,
    deepDiveInsights,
    groups,
    retailUsersObject: retailUsersObject!,
  })

  const totalVotes = pollResults.filter((result) => result.st_id === st_id).length

  const handleChange = (index: number) => {
    let newSelectedValues: number[] = []

    if (isMultiple) {
      newSelectedValues = selectedValues.includes(index)
        ? selectedValues.filter((value) => value !== index)
        : [...selectedValues, index]
    } else {
      newSelectedValues = selectedValues.includes(index) ? [] : [index]
    }

    setSelectedValues(newSelectedValues)

    const status = newSelectedValues.length > 0 ? 2 : 1

    dispatch(
      updateResults({
        votes: newSelectedValues,
        tid,
        st_id,
        type: keys.SUBTASK_TYPES.POLL_SUBTASK,
        resultContextId,
        status: status,
      })
    )
  }

  useEffect(() => {
    const newSelected =
      pollResults
        ?.filter((result) => result.context_id === resultContextId)
        .flatMap((result) => result.poll_selections ?? []) ?? []
    setSelectedValues(newSelected)
  }, [pollResults, resultContextId])

  return (
    <CheckboxGroup value={selectedValues}>
      <Flex flexDir="column" style={{ rowGap: '16px' }}>
        {optionsArray.map(({ label, count, currentQuestionParticipants }, index) => {
          const barWidth = totalVotes > 0 ? `${(count / totalVotes) * 100}%` : '0%'

          return (
            <Box key={index}>
              {isLoading ? (
                <Skeleton height="25px" borderRadius="10px" />
              ) : (
                <>
                  <RadioCheckbox
                    value={label}
                    disabled={isReadMode}
                    isChecked={selectedValues.includes(index)}
                    onChange={() => handleChange(index)}
                  >
                    <PollVotedAvatars
                      count={count}
                      currentQuestionParticipants={currentQuestionParticipants}
                      label={label}
                    />
                  </RadioCheckbox>
                </>
              )}
              <PollBar width={barWidth} isLoading={isLoading} />
            </Box>
          )
        })}
      </Flex>
    </CheckboxGroup>
  )
})
