import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { AppDispatch } from 'config/redux'
import { getAllResults, resetResults } from 'redux/actions/tasks_v2'
import { TaskDescription } from './TaskDescription'
import { Flex } from '@chakra-ui/core'
import { useTaskOverviewContext } from '../../TaskOverviewStackProvider'
import { CreatorRecieverInfo } from './CreatorRecieverInfo'
import Poll from './SubTasks/Poll'
import { MediaSubtasks } from './SubTasks/MediaSubtasks'
import OpenQuesionSubtask from './SubTasks/OpenQuesionSubtask'
import { ResetMenu } from 'components/TasksV2/UI/ResetMenu'
import useLocaleText from 'components/useLocaleText'
import { resetSubtasksResults } from '../../utils'

const Main: React.FC = () => {
  const { task, isShowResetMenu, resultContextId } = useTaskOverviewContext()
  const {
    tid,
    created_by,
    users,
    groups: taskGroups,
    tags: taskTags,
    deadline,
    recurrence_deadline,
    is_recurring,
    recurrence_ts,
  } = task

  const dispatch: AppDispatch = useDispatch()
  const reset_all_answers = useLocaleText('reset_all_answers')

  const endDate = is_recurring ? recurrence_deadline : deadline
  const { subtasks } = useSelector((state: RootState) => state.tasks_v2)
  const allResults = useSelector((state: RootState) => state.tasks_v2.allResults)

  const [isLoading, setIsLoading] = useState(false)

  const subtasksIds = subtasks?.[tid]?.map((subtask) => subtask.st_id)
  const resultsOfCurentContext = allResults[tid]?.filter((result) => result.context_id === resultContextId) || []
  const filteredResultsBySubtasksIds = resultsOfCurentContext.filter((result) => subtasksIds.includes(result.st_id))

  const handleResetResults = async () => {
    setIsLoading(true)
    const isResOk = await dispatch(
      resetResults({
        tid,
        sub_tasks_ids: filteredResultsBySubtasksIds.map((result) => result.st_id),
        result_context_id: resultContextId,
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
    )
    if (!isResOk) {
      return
    }
    resetSubtasksResults({
      tid,
      filteredResultsBySubtasksIds,
      contextId: resultContextId,
      dispatch,
      ...(recurrence_ts ? { recurrence_ts } : {}),
    })
    setIsLoading(false)
  }

  const sortedSubtasks = useMemo(
    () =>
      subtasks?.[tid]?.sort((a, b) => {
        return a.order_index - b.order_index
      }) ?? [],
    [subtasks, tid]
  )
  const handleGetAllResults = useCallback(async () => {
    const pollSubtasksIds = subtasks?.[tid]
      ?.filter((subtask) => subtask.type === keys.SUBTASK_TYPES.POLL_SUBTASK)
      .map((subtask) => subtask.st_id)
    if (!pollSubtasksIds?.length) return

    await dispatch(getAllResults({ task, subtasksIds: pollSubtasksIds }))
  }, [dispatch, subtasks, task, tid])

  useEffect(() => {
    handleGetAllResults()
  }, [handleGetAllResults])

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <CreatorRecieverInfo created_by={created_by} users={users} tags={taskTags} groups={taskGroups} ts={endDate} />
        {isShowResetMenu && (
          <ResetMenu menuItemText={reset_all_answers} handleReset={handleResetResults} isLoading={isLoading} />
        )}
      </Flex>

      <TaskDescription task={task} />

      <Flex
        flexDir="column"
        style={{ rowGap: '50px' }}
        w="100%"
        mt="50px"
        pointerEvents={isLoading ? 'none' : 'all'}
        opacity={isLoading ? 0.5 : 1}
      >
        {sortedSubtasks.length > 0 &&
          sortedSubtasks.map((subtask) => {
            const type = subtask.type
            switch (type) {
              case keys.SUBTASK_TYPES.POLL_SUBTASK:
                return <Poll key={subtask.st_id} pollSubtask={subtask} />
              case keys.SUBTASK_TYPES.OPEN_QUESTION:
                return <OpenQuesionSubtask key={subtask.st_id} subtask={subtask} />

              default:
                return <MediaSubtasks key={subtask.st_id} subtask={subtask} />
            }
          })}
      </Flex>
    </>
  )
}

export default Main
