import React, { memo, useState } from 'react'
import { Box, Flex, Link } from '@chakra-ui/core'
import { RootState, Subtask } from 'constants/interfaces'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import { getFileExtension, openInCloudViewer } from 'utils'
import { resetResults, updateResults } from 'redux/actions/tasks_v2'
import keys from 'constants/keys'
import { AppDispatch } from 'config/redux'
import { useDispatch, useSelector } from 'react-redux'
import { getFormattedLink, isExternalLink, resetSubtasksResults } from 'components/TasksV2/TasksOverview/utils'
import colors from 'constants/colors'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { useResults } from 'components/TasksV2/hooks/useResults'
import { ResetMenu } from 'components/TasksV2/UI/ResetMenu'
import { isRtlSelector } from 'redux/selectors/general'
import useLocaleText from 'components/useLocaleText'
// import { LinkPreview } from 'components/LinkPreview'

interface IProps {
  subtask: Subtask
}
export const LinkSubtask: React.FC<IProps> = memo(({ subtask }) => {
  const { resultContextId, isReadMode, isShowResetMenu, task } = useTaskOverviewContext()
  const { recurrence_ts } = task
  const { type, title, external_link, st_id, tid } = subtask
  const subTaskResult = useResults({ subtask, resultContextId })

  const dispatch: AppDispatch = useDispatch()
  const isRtl = useSelector(isRtlSelector)
  const {
    config: { tags, first_name, last_name, default_group, groups, rid },
    activeGroupID,
  } = useSelector((state: RootState) => state.config)
  const { userResults, tasks } = useSelector((state: RootState) => state.tasks_v2)

  const uid = useSelector((state: RootState) => state.auth.uid)
  const [isLoading, setIsLoading] = useState(false)

  const reset_answer = useLocaleText('reset_answer')

  const handleClickOnLink = () => {
    const { external_link, status } = subtask
    const isExternalLinkHasSpecialPhrase = !!external_link && isExternalLink(external_link)

    const formattedLink = getFormattedLink({
      link: external_link!,
      tasks,
      tid,
      st_id,
      contextId: resultContextId,
      uid: uid!,
      userResults,
      tags,
      first_name,
      last_name,
      default_group,
      groups,
      activeGroupID: activeGroupID!,
      rid,
    })

    const fileExtension = getFileExtension(formattedLink)
    if (keys.extensionsForCloudViewer.includes(fileExtension)) {
      window.open(openInCloudViewer(formattedLink), '_blank')
    } else {
      window.open(formattedLink, '_blank')
    }

    if (isReadMode) {
      return
    }

    if (status !== keys.TASK_STATUSES.DONE.value) {
      dispatch(
        updateResults({
          tid,
          st_id,
          type: keys.SUBTASK_TYPES.LINK_SUBTASK,
          isExternalLink: isExternalLinkHasSpecialPhrase,
          resultContextId,
          status: isExternalLink(external_link!) ? keys.TASK_STATUSES.INPROGRESS.value : keys.TASK_STATUSES.DONE.value,
        })
      )
    }
  }
  const handleResetResults = async () => {
    if (!subTaskResult) {
      return
    }
    setIsLoading(true)
    const isResOk = await dispatch(
      resetResults({
        tid,
        sub_tasks_ids: [subTaskResult.st_id],
        result_context_id: resultContextId,
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
    )
    if (!isResOk) {
      return
    }

    resetSubtasksResults({
      tid,
      filteredResultsBySubtasksIds: [subTaskResult],
      contextId: resultContextId,
      dispatch,
      ...(recurrence_ts ? { recurrence_ts } : {}),
    })
    setIsLoading(false)
  }

  return (
    <WidgetWrapper
      cursor={isReadMode ? 'default' : 'pointer'}
      p="15px"
      onClick={handleClickOnLink}
      pointerEvents={isLoading ? 'none' : 'all'}
      opacity={isLoading ? 0.5 : 1}
    >
      <WidgetIcon subtaskType={type} />

      {subTaskResult?.status === keys.TASK_STATUSES.DONE.value && <StatusCircle color={colors.greenPoll} />}
      {isShowResetMenu && (
        <Box
          pos="absolute"
          top="3px"
          right={isRtl ? 'unset' : '25px'}
          left={isRtl ? '25px' : 'unset'}
          onClick={(e) => e.stopPropagation()}
        >
          <ResetMenu menuItemText={reset_answer} handleReset={handleResetResults} isLoading={isLoading} />
        </Box>
      )}
      <Flex mt="35px" mb="25px" flexDir="column" justifyContent={'center'} style={{ rowGap: '5px' }}>
        <SubtaskTitile title={title} mt="0px" mb="0px" />
        <Link
          color="blue.500"
          fontSize="14px"
          _focus={{ boxShadow: 'none', outline: 'none', textDecoration: 'none' }}
          textAlign="center"
        >
          {external_link}
        </Link>
      </Flex>

      {/* {external_link && <LinkPreview width="100%" url={external_link} />} */}
    </WidgetWrapper>
  )
})
