import WidgetHeader from 'components/TasksV2/UI/WidgetHeader'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import React, { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@chakra-ui/core'
import { VideoQuestion } from './VideoQuestion'
import { useWidgetErrorMessage } from '../../useWidgetErrorMessage'
import { WidgetProps } from '../types'

const VideoWidget: React.FC<WidgetProps> = memo(({ index, isDragging, field, remove, subtaskPath }) => {
  const { control, errors } = useFormContext()

  const questionName = `${subtaskPath}.title`

  const pollError = useWidgetErrorMessage({ errors, index, field })

  return (
    <WidgetWrapper pos="relative" p="10px" isDragging={isDragging} isError={!!pollError} errorMessage={pollError}>
      <WidgetHeader onRemove={remove} subtaskType={field?.type} />
      <VideoQuestion questionName={questionName} field={field} />

      {/*Register constant values in the form context*/}
      <Controller
        name={`${subtaskPath}.type`}
        control={control}
        as={<Box />}
        key={`${subtaskPath}.type`}
        defaultValue={field?.type}
      />
    </WidgetWrapper>
  )
})

export default VideoWidget
