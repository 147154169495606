import React, { useMemo } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { BackButton } from 'components/TasksV2/UI/BackButton'
import TaskModal from 'components/TasksV2/UI/TaskModal'
import {
  InsightsContentStack,
  InsightsContentType,
  InsightsStackProvider,
  useInsightsContext,
} from '../InsightsStackProvider'
import MediaResultsPreview from '../Content/AllContextMediaResultsPreview'
import MediaResultsOverview from '../Content/AllContextsMediaResultsOverview'
import useLocaleText from 'components/useLocaleText'

interface IProps {
  onClose: () => void
  tid: string
  content?: InsightsContentStack
  selectedRecurrenceTs: string | null
}

const InsightsModalContent: React.FC<IProps> = ({ onClose, tid }) => {
  const { insights, contentStack, closeContent } = useInsightsContext()
  const { title } = insights[tid]
  const t_media_overview = useLocaleText('t_media_overview')

  const currentContent = contentStack[contentStack.length - 1]

  const headerTitle = useMemo(() => {
    if (!currentContent) return null
    const { contentType, props } = currentContent
    switch (contentType) {
      case InsightsContentType.INSIGHTS:
        return title
      case InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW:
        return t_media_overview
      case InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_OVERVIEW:
        return props.title
      default:
        return null
    }
  }, [currentContent, t_media_overview, title])

  return (
    <>
      <TaskModal
        isOpen={true}
        onClose={onClose}
        modalFooterProps={{ background: '#f4f4f4' }}
        modalContentProps={
          currentContent?.contentType &&
          currentContent.contentType === InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW
            ? { w: '80vw', maxW: '1400px' }
            : { w: '50vw', maxW: '650px' }
        }
        header={
          <Flex justifyContent="space-between" h="60px">
            <BackButton title={headerTitle} event={closeContent} />
          </Flex>
        }
        body={
          <>
            {contentStack.map((content, index) => {
              const { contentType, props } = content
              const isTopContent = index === contentStack.length - 1

              switch (contentType) {
                case InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW:
                  return (
                    <Box
                      display={isTopContent ? 'block' : 'none'}
                      h="100%"
                      mx="-25px"
                      key={`${index}-${content}`}
                      overflow="hidden"
                    >
                      <MediaResultsPreview key={index} />
                    </Box>
                  )
                case InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_OVERVIEW:
                  return (
                    props?.contextId &&
                    props?.subtasks && (
                      <Flex
                        display={isTopContent ? 'flex' : 'none'}
                        h={{ base: 'auto', lg: '100%' }}
                        flexDir="column"
                        justifyContent="space-between"
                        key={`${index}-${content}`}
                      >
                        <MediaResultsOverview contextId={props.contextId} tid={tid} subtasks={props.subtasks} />
                      </Flex>
                    )
                  )
                default:
                  return null
              }
            })}
          </>
        }
        footer={<></>}
      />
    </>
  )
}
export const InsightsModal: React.FC<IProps> = (props) => {
  return (
    <InsightsStackProvider
      content={props.content}
      tid={props.tid}
      onClose={props.onClose}
      recurrenceTs={props.selectedRecurrenceTs}
    >
      <InsightsModalContent {...props} />
    </InsightsStackProvider>
  )
}
